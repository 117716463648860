import './App.css';

import Header from './shared/header';
import Footers from './shared/footer';
import MainPage from './components/home/MainPage';
import NotFoundPage from './NotFoundPage';
import BattlesPage from './components/battles/BattlesPage';
import { Route, Switch } from 'react-router-dom';

/* Imperium Links */
/* Black Templar Links */
import BTHQPage from './components/imperium/black-templars/HQPage';
import BTTroopsPage from './components/imperium/black-templars/TroopsPage';
import BTElitesPage from './components/imperium/black-templars/ElitesPage';
import BTFastAttackPage from './components/imperium/black-templars/FastAttackPage';
import BTHeavySupportPage from './components/imperium/black-templars/HeavySupportPage';
import BTFlyerPage from './components/imperium/black-templars/FlyerPage';

/* Imperial Guard Links */
import IGHQPage from './components/imperium/imperial-guard/HQPage';
import IGTroopsPage from './components/imperium/imperial-guard/TroopsPage';
import IGElitesPage from './components/imperium/imperial-guard/ElitesPage';
import IGFastAttackPage from './components/imperium/imperial-guard/FastAttackPage';
import IGHeavySupportPage from './components/imperium/imperial-guard/HeavySupportPage';
import IGSuperHeavy from './components/imperium/imperial-guard/SuperHeavyPage';

/* Xenos Links */
/* Tyranid Links */
import TYHQPage from './components/xenos/tyranids/HQPage';
import TYTroopsPage from './components/xenos/tyranids/TroopsPage';
import TYElitesPage from './components/xenos/tyranids/ElitesPage';
import TYFastAttackPage from './components/xenos/tyranids/FastAttackPage';
import TYHeavySupportPage from './components/xenos/tyranids/HeavySupportPage';
import Card from './shared/card/card';

const App = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route path={['/home/MainPage', '/']} exact component={MainPage} />
        {/*Black Templars*/}
        <Route path={['/BTTroops']} component={BTTroopsPage} />
        <Route path={['/BTHQ']} component={BTHQPage} />
        <Route path={['/BTElites']} component={BTElitesPage} />
        <Route path={['/BTFastAttack']} component={BTFastAttackPage} />
        <Route path={['/BTHeavySupport']} component={BTHeavySupportPage} />
        <Route path={['/BTFlyer']} component={BTFlyerPage} />

        {/*Imperial Guard*/}
        <Route path={['/IGTroops']} component={IGTroopsPage} />
        <Route path={['/IGHQ']} component={IGHQPage} />
        <Route path={['/IGElites']} component={IGElitesPage} />
        <Route path={['/IGFastAttack']} component={IGFastAttackPage} />
        <Route path={['/IGHeavySupport']} component={IGHeavySupportPage} />
        <Route path={['/IGSuperHeavy']} component={IGSuperHeavy} />
        {/*Tyranid*/}
        <Route path={['/TYTroops']} component={TYTroopsPage} />
        <Route path={['/TYHQ']} component={TYHQPage} />
        <Route path={['/TYElites']} component={TYElitesPage} />
        <Route path={['/TYFastAttack']} component={TYFastAttackPage} />
        <Route path={['/TYHeavySupport']} component={TYHeavySupportPage} />

        <Route path='/Battles' component={BattlesPage} />
        {/*Card Routes*/}
        <Route path={['/{Troop.id}']} component={Card} />
        <Route component={NotFoundPage} />
      </Switch>
      <Footers />
    </>
  );
};

/* function App() {
  return (
    <>
      <Headers />
      <MainPage />
      <Footers />
    </>
  );
} */

export default App;
