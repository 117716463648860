import React from 'react';

import Card from '../../../shared/card/card';
import Captian from './images/Captain.jpg';
import HeavyIntercessor from './images/HeavyIntercessorSgt.jpg';
import CompanyChampion from './images/CompanyChampion.jpg';

const Data = {
  Title: 'Friend',
  abilites: ['Touch', 'Push', 'Jump', 'Track'],

  stats: {
    Health: 100,
    Movement: '2in',
    Special: 'Emergency Evac',
  },
};

const BTHQPage = () => {
  return (
    <>
      <div className='container-fluid bg-secondary p-2'>
        <h1 className='text-center'>Black Templar HQ</h1>
      </div>

      <div id='cardcontainer' className='container d-flex'>
        <div className='row'>
          <div className='col col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-around'>
            <Card title='Captain ()' imageUrl={Captian} body={Data.title} />
          </div>
          <div className='col col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-around'>
            <Card
              title='Heavy Intercessors'
              imageUrl={HeavyIntercessor}
              body='The Heavy Intercessors....'
            />
          </div>
          <div className='col col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-around'>
            <Card
              title='[Company Champion]'
              imageUrl={CompanyChampion}
              body='[Company Chamion Name Goes HERE] is a beast.....'
            />
          </div>
          <div className='col col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-around'>
            <Card
              title='Blank'
              imageUrl={''}
              body='This has been left blank intentionally'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BTHQPage;
