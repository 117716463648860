import React from "react";

function Header() {
  return (
    <header className="container-fluid">
      {/* Nav Bar */}
      <nav
        className="
						navbar navbar-expand-sm navbar-light
						bg-light
						justify-content-between
					"
      >
        <div className="nav-item">
          {/* Crusade Title */}
          <a className="btn text-uppercase" href="/">
            
            THE (FILL) Crusade
          </a>
          {/* SearchBar */}
          <form className="form-inline">
            <input
              className="form-control"
              type="search"
              placeholder="Search"
            />
            <button className="btn btn-outline-secondary my-2" type="submit">
              Search
            </button>
          </form>
        </div>
        {/* Toggler */}

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navBarToggler"
          aria-controls="navBarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Toggled Menu */}

        <div
          className="collapse navbar-collapse flex-grow-0"
          id="navBarToggler"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>

            {/*Location Dropdown Here*/}
            <li className="nav-item dropdown">
              <div>
                <button
                  className="nav-link dropdown-toggle"
                  href=""
                  id="ImperiumDropDown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Location
                </button>

                {/*Locations Here */}

                <div className="nav-item dropdown">
                  <div
                    className="dropdown-menu"
                    aria-labelledby="blackTemplarsDropdown"
                  >
                    <div className="">
                      <a className="dropdown-item" href="/IceWorldLocation">
                        Ice World
                      </a>
                      <a
                        className="dropdown-item"
                        href="/IndustrialWorldLocation"
                      >
                        Industrial World
                      </a>
                      <a className="dropdown-item" href="/ForestWorldLocation">
                        Forest World
                      </a>
                      <a className="dropdown-item" href="/StarBaseLocation">
                        Star Base
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            {/*Imperium DropDown Here */}
            <li className="nav-item dropdown">
              <div>
                <button
                  className="nav-link dropdown-toggle"
                  href=""
                  id="ImperiumDropDown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Imperium
                </button>

                <div className="nav-item dropdown">
                  <div
                    className="dropdown-menu"
                    aria-labelledby="blackTemplarsDropdown"
                  >
                    {" "}
                    <div className="">
                      {/* Black Templars*/}
                      <h6 className="dropdown-header text-center">
                        Black Templars
                      </h6>
                      <hr className="w-50 my-0 bg-danger" />
                      <a className="dropdown-item" href="/BTHQ">
                        HQ
                      </a>
                      <a className="dropdown-item" href="/BTTroops">
                        Troops
                      </a>
                      <a className="dropdown-item" href="/BTElites">
                        Elites
                      </a>
                      <a className="dropdown-item" href="/BTFastAttack">
                        Fast Attack
                      </a>
                      <a className="dropdown-item" href="/BTHeavySupport">
                        Heavy Support
                      </a>
                      <a className="dropdown-item" href="/BTFlyer">
                        Flyer
                      </a>
                      <div className="dropdown-divider"></div>

                      {/* Imperial Guard*/}
                      <h6 className="dropdown-header text-center">
                        Imperial Guard
                      </h6>
                      <hr className="w-50 my-0 bg-warning" />

                      <a className="dropdown-item" href="/IGHQ">
                        HQ
                      </a>
                      <a className="dropdown-item" href="/IGTroops">
                        Troops
                      </a>
                      <a className="dropdown-item" href="/IGElites">
                        Elites
                      </a>
                      <a className="dropdown-item" href="/IGFastAttack">
                        Fast Attack
                      </a>
                      <a className="dropdown-item" href="/IGHeavySupport">
                        Heavy Support
                      </a>
                      <a className="dropdown-item" href="/IGSuperHeavy">
                        Super Heavy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            {/*Xenos Dropdown Here */}
            <li className="nav-item dropdown">
              <div>
                <button
                  className="nav-link dropdown-toggle"
                  href=""
                  id="ImperiumDropDown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Xenos
                </button>


                <div className="nav-item dropdown">
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="blackTemplarsDropdown"
                  >
                    {" "}
                    <div className="">
                      {/* Tyranids*/}
                      <h6 className="dropdown-header text-center">Tyranids</h6>
                      <hr className="w-50 my-0 bg-info" />
                      <a className="dropdown-item" href="/TYHQ">
                        HQ
                      </a>
                      <a className="dropdown-item" href="/TYTroops">
                        Troops
                      </a>
                      <a className="dropdown-item" href="/TYElites">
                        Elites
                      </a>
                      <a className="dropdown-item" href="/TYFastAttack">
                        Fast Attack
                      </a>
                      <a className="dropdown-item" href="/TYHeavySupport">
                        Heavy Support
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
			
			<li className="nav-item">
              <a className="nav-link" href="/Battles">
                Battles
              </a>
            </li>
			<li className="nav-item">
              <a className="nav-link" href="/Data">
                Data
              </a>
            </li>
		  </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
