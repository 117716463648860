import React from "react";

import Card from "../../../shared/card/card";

import Haruspex from "./images/Haruspex.jpg";

const TYElitesPage = () => {
	return (
		<>
		<h1 className="text-center bg-secondary p-2">Tyranid Elites</h1>
		<div className="selected-card-container"></div>
		<div id="cardcontainer" className="container d-flex">
			<div className="row">
				<div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-around">
					<Card
						title="Haruspex"
						imageUrl={Haruspex}
						body="The Haruspex are..."
					/>
				</div>
				<div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-around">
					<Card
						title="Hive Guard"
						imageUrl={''}
						body="The Hive Guard are...."
					/>
				</div>
				<div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3  d-flex justify-content-around">
					<Card
						title="Lictor"
						imageUrl={''}
						body="The Lictor is..."
					/>
				</div>
				<div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3  d-flex justify-content-around">
					<Card
						title="Zoanthropes"
						imageUrl={''}
						body="The Zoanthropes are..."
					/>
				</div>
			</div>
		</div>
	</>
	);
};

export default TYElitesPage;
