import React from "react";
import Timeline from "../../shared/timeline";
import CommisarImage from "./commisar.jpg";

function MainPage() {
	return (
		<>
			<Timeline />
			<div className="container-fluid border px-5" id="mainBody">
				<div className="row pt-3 ">
					<div className="col col-6 py-3">
						<img
							src={CommisarImage}
							className="shadow"
							alt="Commisar"
							height="150px"
						/>
					</div>
					<div className="col col-12 p-2" id="titleBody">
						<h1>Commisar Awaites...</h1>
					</div>

					<div className="col-12 col-sm-12 p-2 my-2 shadow " id="contentBody">
						<p>
							Welcome to the Crusade {/* Fill */} webpage. The grim darkness of
							the far future that will be played out in tabletop form. Thanks to
							Games Workshop’s immersive fantasy setting of Warhammer 40,000 we
							will be using their back story and general game mechanics to play
							out this narrative campaign. The current tabletop game mechanic
							will be 9th edition 40k. This is our fan fiction story that should
							end up as fun dive into make conquest across the stars.
						</p>
						<p>
							In the dark future there is only war. Mankind is spread amongst
							the stars in a galaxy spanning empire based on the strict and
							devote faith centered around the Emperor of Mankind, Master of
							Terra. Forever entombed on his golden throne. Forever denied both
							life and death. When the people of his empire face the darkest of
							threats though, his angles are swiftly dispatched.
						</p>
						<p>
							The Emperor’s angles of death are biologically engineered supermen
							sworn to uphold the creed of protecting his empire and all those
							in it. The most zealous of his space marine chapters are the Black
							Templars. After the introduction of the Primarius Space Marines
							into the chapter 200 years ago, they have more than earned their
							fair share of battle honors. Now seasoned veterans they receive
							word that their next crusade will be to punishing the passing
							Tyrranid hive fleet. A splinter of the hive fleet Leviathan broke
							off and is prowling through the {/*fill*/} system. Captain{" "}
							{/*fill*/} was tasked by the High Marshall to impede, harass and
							counter this swarm. And punish the tyrranid swarm he has. Now we
							find the Captain replenishing the defenses of Forge World of{" "}
							{/*FILL*/}.
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default MainPage;
