import React from "react";

import Card from "../../../shared/card/card";

import HiveTyrantImage from "./images/HiveTyrant.jpg";
import Neurothrope from "./images/Neurothrope.jpg";
import TyranidPrime from "./images/TyranidPrime.jpg";

const TYHQPage = () => {
	return (
		<>
			<h1 className="text-center bg-secondary p-2">Tyranid HQ</h1>
			<div className="selected-card-container"></div>
			<div id="cardcontainer" className="container d-flex">
				<div className="row">
					<div className="col col-sm-6 col-md-6 col-lg-4 d-flex justify-content-around">
						<Card
							title="Hive Tyrant"
							imageUrl={HiveTyrantImage}
							body="The Hive Tyrant is..."
						/>
					</div>
					<div className="col col-sm-6 col-md-6 col-lg-4 d-flex justify-content-around">
						<Card
							title="Neurothrope"
							imageUrl={Neurothrope}
							body="The Neurothrope is...."
						/>
					</div>
					<div className="col col-sm-6 col-md-6 col-lg-4  d-flex justify-content-around">
						<Card
							title="Tyranid Prime"
							imageUrl={TyranidPrime}
							body="The Tyranid Warriors are..."
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default TYHQPage;
