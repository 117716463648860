import React from "react";
import LordCommisar from "./Lord_Castellan_Creed.jpg";
import CityImage from "./city.jpg";
import ArtyImage from "./arty.jpg";

function Timeline() {
  function changeBackground(e) {
    e.target.style.background = "red";
  }
  function resetBackground(e) {
    e.target.style.background = "";
  }

  return (
    <div className="container-fluid" id="timeline">
      <div
        id="carouselExampleIndicators mb-0"
        className="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="d-block w-100"
              src={LordCommisar}
              alt="First slide"
            />
            <div className="carousel-caption text-warning d-none d-md-block">
              <h5>Commisar {/*FILL*/}!!</h5>
              <p>
                The city slumbers as the enemies gather in the darkness of space
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={CityImage} alt="Second slide" />
            <div className="carousel-caption text-warning d-none d-md-block">
              <h5>{/*FILL*/} City</h5>
              <p>
                The city slumbers as the enemies gather in the darkness of space
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={ArtyImage} alt="Third slide" />
            <div className="carousel-caption text-warning d-none d-md-block">
              <h5>Boom Canons</h5>
              <p>What is the sound of metal rain?</p>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      <div className="/" id="timeLineTabs">
        <div className="">
          <ul className="nav nav-tabs justify-content-center">
            <li className="nav-item">
              <a
                className="nav-link"
                onMouseOver={changeBackground}
                onMouseLeave={resetBackground}
                href="implement"
              >
                The Beggining
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link disabled" href="fill">
                And Then
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link disabled" href="fill">
                And Then....
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link disabled" href="fill">
                And Then....
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link disabled" href="fill">
                And Finally
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div className="jumbotron" id="jumbotronTimline"></div>

          {/*Content Goes Here for TimeLine*/}
        </div>
      </div>
    </div>
  );
}

export default Timeline;
