import React from "react";

import Card from "../../../shared/card/card";
const TYFastAttackPage = () => {
	return (
		<>
		<h1 className="text-center bg-secondary p-2">Tyranid Fast Attack Unit(s)</h1>
		<div className="selected-card-container"></div>
		<div id="cardcontainer" className="container d-flex justify-content-center">
			<div className="row ">
				<div className="col-12 ">
					<Card
						title="Raveners"
						imageUrl={''}
						body="The Raveners are..."
					/>
				</div>
			</div>
		</div>
	</>
	);
};

export default TYFastAttackPage;
