import React from "react";

const IGHeavySupportPage = () => {
	return (
		<div className="jumbotron">
			<div className="row">
				<div className="col"></div>
			</div>
		</div>
	);
};

export default IGHeavySupportPage;
