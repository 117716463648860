import React from "react";

import Card from "../../../shared/card/card";

/* import Carnifexes from "./images"
import Tyrannofex from "./images" */

const TYHeavySupportPage = () => {
	return (
		<>
		<h1 className="text-center bg-secondary p-2">Tyranid Heavy Support</h1>
		<div className="selected-card-container"></div>
		<div id="cardcontainer" className="container ">
			<div className="row d-flex justify-content-center">
				<div className="col col-sm-6 px-2">
					<Card
						title="Carnifexes"
						imageUrl={''}
						body="The Carnifexes are..."
					/>
				</div>
				<div className="col col-sm-6">
					<Card
						title="Tyrannofex"
						imageUrl={''}
						body="The Tyrannofex are...."
					/>
				</div>
		
			</div>
		</div>
	</>
	);
};

export default TYHeavySupportPage;
