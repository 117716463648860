import React from "react";
import Card from "../../../shared/card/card";

import Hormagaunts from "./images/Hormagaunts.jpg"
import RipperSwarms from "./images/RipperSwarm.jpg"
import Termagants from "./images/Termagants.jpg"
import TyranidWarriors from "./images/TyranidWarrior.jpg"

const TYTroopsPage = () => {
	return (
		<>
		<h1 className="text-center bg-secondary p-2">Tyranid Troops</h1>
		<div className="selected-card-container"></div>
		<div id="cardcontainer" className="container d-flex">
			<div className="row">
				<div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-around">
					<Card
						title="Hormagaunts"
						imageUrl={Hormagaunts}
						body="The Hormagaunts are..."
					/>
				</div>
				<div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-around">
					<Card
						title="RipperSwarms"
						imageUrl={RipperSwarms}
						body="The RipperSwarms are...."
					/>
				</div>
				<div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3  d-flex justify-content-around">
					<Card
						title="Termagants"
						imageUrl={Termagants}
						body="The Termagants are..."
					/>
				</div>
				<div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3  d-flex justify-content-around">
					<Card
						title="Tyranid Warriors"
						imageUrl={TyranidWarriors}
						body="The Tyranid warriors are..."
					/>
				</div>
			</div>
		</div>
	</>
	);
};

export default TYTroopsPage;
